import { uniqueId } from 'lodash'
import { ISelectOption } from '../types/interfaces'
import * as Yup from 'yup'

// Responsive Constants
export const generalBsBreakpoint = 'lg' //BootStrap sets the magic word "lg" for large screens at >=992px
export const generalBsBreakpointValue = 992 // should match above choice

export const mediumBsBreakpointValue = 768
export const xlBsBreakpointValue = 1200

export const maxProductsEditorListResults = 350

export const showAboveBreakpoint = `d-none d-${generalBsBreakpoint}-block`
export const showBelowBreakpoint = `d-${generalBsBreakpoint}-none`

export const savedCartsWithItemsLimitLanding = 2
export const openOrdersLimitLanding = 2

export const SCHEDULED_TO_SHIP_ORDERS_STATUS_ID = 20

export const RECAPTCHA_SITE_KEY = '6LfKDz8UAAAAAIY7sLFdYlKO_MhN9qClLMCZr9mw'

export const COLOR_PREFIX = 'product_color'
export const EFFECT_PREFIX = 'product_effect'
export const IGNITION_TYPE_PREFIX = 'product_ignition_type'
export const FIRING_PATTERN_PREFIX = 'product_firing_pattern'
export const DEBRIS_PROFILE_PREFIX = 'product_debris_profile'

export const MARKETING_PAGE_CATEGORY_PREFIX = 'page_category'

export const initiallySelectedStoreSettings = [
  'recaptcha',
  'text_cart_contact_help',
  'terms_of_use_filename',
  'privacy_policy_filename',
  'just_arrived_days',
] // any adds here will need to be made to IStoreSettingsData a well

export const initiallySelectedAppSettings = [
  'glow_enabled',
  'rush_shipping_in_cart_enabled',
  'aws_public_files_path',
  'tiny-key',
  'wholesale_domain',
  'proline_domain',
]
export const keywordForVolumePricing = 'volume'
export const keywordForSpecialPricing = 'special'
export const keywordForContainerPricing = 'container'
export const spiritPhoneNumber = '(573) 447-1776'
export const spiritSupportEmail = 'service@76fireworks.com'
export const excelRequestWarehouseName = 'boonville'
export const excelRequestStockStatus = 'onorder'

export const HEADER_HEIGHT_DESKTOP = 55

export const DOCUMENT_TYPE_SALES_TAX = 'Sales Tax'
export const DOCUMENT_TYPE_PRO_USE = 'Pro Use'
export const DOCUMENT_TYPE_INSURANCE = 'Insurance'

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/

export const currentYear = new Date().getFullYear()

export const passwordValidationSchema = Yup.string() // note: password is in 3 places in wholesale_frontend codebase
  .required('Required')
  .min(8, 'must be 8 characters or more')
  .matches(/[a-z]+/, 'must contain lowercase character')
  .matches(/[A-Z]+/, 'must contain uppercase character')
  .matches(/[!@#$%^&*()?><_+~{}`]+/, 'must contain special character')
  .matches(/\d+/, 'must contain number')

export const passwordRequirements = [
  ' 8 characters',
  'a lowercase',
  'an uppercase',
  'a special character',
  'a number',
]

export const passwordValidationTests = (inputValue: string) => {
  return [
    inputValue.length >= 8,
    /[a-z]+/.test(inputValue),
    /[A-Z]+/.test(inputValue),
    /[!@#$%^&*()?><_+~{}`]+/.test(inputValue),
    /\d+/.test(inputValue),
  ]
}

export enum PricingUi {
  SUPPLIERS = '1000',
  MANUFACTURERS = '1001',
  FOREX = '1002',
  INBOUND_TEMPLATE_ITEMS = '1003',
  CATEGORY_MULTIPLIER = '1004',
  PRODUCT_MULTIPLIER = '1005',
  REALIZATION_ESTIMATE = '1006',
  INBOUND_FREIGHT = '1009',
  SUPPLIER_MANUFACTURER_DISCOUNT = '1010',
  INBOUND_TEMPLATE = '1011',
}

// export const pricingUiIds = {
//   price_suppliers: '1000',
//   price_manufacturers: '1001',
//   price_forex: '1002',
//   price_inbound_template_items: '1003',
//   price_category_multiplier: '1004',
//   price_product_multiplier: '1005',
//   price_realization_estimate: '1006',
//   price_inbound_freight: '1009',
//   price_supplier_manufacturer_discount: '1010',
// }

export const emptyOptionSelect = [
  { value: '', label: 'Loading...', id: uniqueId() },
]

export const yesNoOptions = [
  { value: '1', label: 'Yes', id: uniqueId() },
  { value: '0', label: 'No', id: uniqueId() },
]

export const resultsPerPageOptions = [
  { value: '10', label: '10', id: uniqueId() },
  { value: '25', label: '25', id: uniqueId() },
  { value: '50', label: '50', id: uniqueId() },
  { value: '100', label: '100', id: uniqueId() },
]

export const productListTypes = {
  explicit: '1',
  just_arrived: '2',
  coming_soon: '3',
  deal: '4',
  closeout: '5',
  product_year_added: '6',
}

export const statusFilterOptions: Array<ISelectOption> = [
  { value: 'Active', label: 'Active', id: uniqueId() },
  { value: 'Inactive', label: 'Inactive', id: uniqueId() },
  { value: 'All', label: 'All', id: uniqueId() },
]

export const projectIdOptions: Array<ISelectOption> = [
  { value: '', label: '<Select Project>', id: uniqueId() },
  { value: '12', label: 'Wholesale', id: uniqueId() },
  { value: '14', label: 'Proline', id: uniqueId() },
]

export const projectOptions: Array<ISelectOption> = [
  { value: '12', label: 'Wholesale', id: uniqueId() },
  { value: '14', label: 'Proline', id: uniqueId() },
]

export const projectIdFilterOptions: Array<ISelectOption> = [
  { value: '12', label: 'Wholesale', id: uniqueId() },
  { value: '14', label: 'Proline', id: uniqueId() },
  { value: '', label: 'All', id: uniqueId() },
]

export const creditMemoTypes = {
  typeOrder: 'ORDER',
  typeProduct: 'PRODUCT',
  typeCustomer: 'CUSTOMER',
}

export const monthOptions: Array<ISelectOption> = [
  { value: '', label: '<Select Month>', id: '' },
  { value: '1', label: 'January', id: '1' },
  { value: '2', label: 'February', id: '2' },
  { value: '3', label: 'March', id: '3' },
  { value: '4', label: 'April', id: '4' },
  { value: '5', label: 'May', id: '5' },
  { value: '6', label: 'June', id: '6' },
  { value: '7', label: 'July', id: '7' },
  { value: '8', label: 'August', id: '8' },
  { value: '9', label: 'September', id: '9' },
  { value: '10', label: 'October', id: '10' },
  { value: '11', label: 'November', id: '11' },
  { value: '12', label: 'December', id: '12' },
]
