import React from 'react'
import { Form, FloatingLabel } from 'react-bootstrap'

export interface RwOptionSelectProps {
  className?: string
  label: string
  hint?: string
  name?: string
  id?: string
  errors?: any
  options: string[] | object[]
  [x: string]: any
}

const RwOptionSelect: React.FC<RwOptionSelectProps> = (
  props: RwOptionSelectProps,
) => {
  const {
    className = '',
    placeholder,
    label,
    options,
    hint,
    errors = '',
    name,
    id = name,
    ...rest
  } = props

  return (
    <Form.Group controlId={id} className={className}>
      <FloatingLabel label={label} className={`mb-2`}>
        <Form.Select name={name} {...rest}>
          {placeholder ? (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          ) : (
            <option value="" disabled hidden>
              select option
            </option>
          )}
          {options.map((datum: any) => (
            <option value={`${datum.value}`} key={`${datum.value}`}>
              {datum.label}
            </option>
          ))}
        </Form.Select>

        <Form.Text className="text-muted">{hint}</Form.Text>
        <Form.Control.Feedback type="invalid">
          <>{errors}</>
        </Form.Control.Feedback>
      </FloatingLabel>
    </Form.Group>
  )
}

export default RwOptionSelect
