import React, { useEffect, useState } from 'react'
import { useInvoiceExportInvoicesList } from '../../../../queries/invoiceExport'
import { Modal, Button, Form } from 'react-bootstrap'
import RwIconButton from '../../../molecules/RwButton/RwIconButton'
import RwTable from '../../../molecules/RwTable'
import RwTableCell from '../../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../../molecules/RwTable/RwTableBody'
import { IInvoiceExportInvoice } from '../../../../queries/invoiceExport/types'
import RwTableCellEnd from '../../../molecules/RwTable/RwTableCellEnd'
import RwTableRow from '../../../molecules/RwTable/RwTableRow'
import RwNavItem from '../../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../../molecules/RwNav/RwNavBar'

import { useAccountingContext } from '../../../../context/accounting/AccountingProvider'

import { useUserContext } from '../../../../context/user/UserProvider'

import CustomSelect from '../../../molecules/Select/CustomSelect'
import { ISelectOption } from '../../../../types/interfaces'
import RwNavHeader from '../../../molecules/RwNav/RwNavHeader'
import { PinKey } from '../../../molecules/RwNav/types'
import ReconcileModal from './ReconcileModal'

export const ExportInvoicesBreadcrumb = () => {
  return <span>Export Invoices</span>
}

const InvoiceExport: React.FC = () => {
  const [actionMarkInvoices, setActionMarkInvoices] = useState<ISelectOption[]>(
    [],
  )
  const [actionCheckInvoices, setActionCheckInvoices] = useState<
    ISelectOption[]
  >([])
  const [justLoaded, setJustLoaded] = useState<boolean>(false)
  const { isDarkMode } = useUserContext()
  const [tableData, setTableData] = useState<any>(null)

  const [showModal, setShowModal] = useState(false)
  const [showReconcileModal, setShowReconcileModal] = useState(false)

  const {
    exportSelectedInvoiceExportInvoices,
    markSelectedExportedVerified,
    markSelectedExportedUnverified,
    unmarkSelectedExportedUnverified,
    markSelectedNotProcessing,
    exportCheckedInvoicesPdfSingle,
    exportCheckedInvoicesPdf,
    downloadReconcileReport,
  } = useAccountingContext()

  const fetchData = async () => {
    refetch()
  }

  const handleMarkInvoiceSelect = (selected_option: string) => {
    switch (selected_option) {
      case 'mark_as_exported_and_verified':
        markCheckedExportedVerified()
        return
      case 'mark_as_exported_but_un-verified':
        markCheckedExportedUnVerified()
        return
      case 'un-mark_as_exported_but_un-verified':
        unmarkCheckedExportedUnVerified()
        return
      case 'mark_as_not_processing':
        markCheckedAsNotProcessing()
        return
      default:
        return
    }
  }

  useEffect(() => {
    setActionMarkInvoices([
      {
        id: 'action_option_mark_as_exported_and_verified',
        value: 'mark_as_exported_and_verified',
        label: 'Exported and Verified',
      },
      {
        id: 'action_option_mark_as_exported_but_un-verified',
        value: 'mark_as_exported_but_un-verified',
        label: 'Exported but Un-verified',
      },
      {
        id: 'action_option_un-mark_as_exported_but_un-verified',
        value: 'un-mark_as_exported_but_un-verified',
        label: 'Un-Mark Exported but Un-verified',
      },
      {
        id: 'action_option_mark_as_not_processing',
        value: 'mark_as_not_processing',
        label: 'Not Processing',
      },
    ])
  }, [])

  const handleCheckInvoiceSelect = (selected_option: string) => {
    switch (selected_option) {
      case 'check_edited_after_ship':
        selectEditedAfterShip()
        return
      case 'check_tx_with_tax':
        selectTxTax()
        return
      case 'check_tx_tax_discount':
        selectTxTaxDiscount()
        return
      case 'check_non-tx_tax_discount':
        selectNonTxTaxDiscount()
        return
      case 'check_non-tx_tax':
        selectNonTxTax()
        return
      case 'check_exported_unverified':
        selectExportedUnverified()
        return
      default:
        return
    }
  }

  useEffect(() => {
    setActionCheckInvoices([
      {
        id: 'action_option_check_edited_after_ship',
        value: 'check_edited_after_ship',
        label: 'Edited after Ship',
      },
      {
        id: 'action_option_check_tx_with_tax',
        value: 'check_tx_with_tax',
        label: 'TX with tax',
      },
      {
        id: 'action_option_check_tx_tax_discount',
        value: 'check_tx_tax_discount',
        label: 'TX tax & discount',
      },
      {
        id: 'action_option_check_non-tx_tax_discount',
        value: 'check_non-tx_tax_discount',
        label: 'Non-TX tax & discount',
      },
      {
        id: 'action_option_check_non-tx_tax',
        value: 'check_non-tx_tax',
        label: 'Non-TX tax',
      },
      {
        id: 'action_option_check_exported_unverified',
        value: 'check_exported_unverified',
        label: 'Exported Un-verified',
      },
    ])
  }, [])

  useEffect(() => {
    // Function to fetch or update your data
    fetchData()
  }, []) // Assuming fetchData is a callback that doesn't change, or you can include any variables that would trigger a re-fetch.

  const { data, refetch, isLoading, isFetching, isPreviousData, status } =
    useInvoiceExportInvoicesList()

  useEffect(() => {
    if (data) {
      setTableData(data?.invoices)
      if (justLoaded == false) {
        setJustLoaded(true)
        selectAll()
      }
    }
  }, [data])

  // checkbox functions
  const [selectedInvoices, setSelectedInvoices] = useState<Array<string>>([])

  const addToSelected = (id: string | undefined) => {
    if (id) {
      setSelectedInvoices([id, ...selectedInvoices])
    }
  }

  const removeFromSelected = (id: string | undefined) => {
    if (id) {
      setSelectedInvoices(
        selectedInvoices.filter((orders_id) => {
          return orders_id !== id
        }),
      )
    }
  }

  const selectNone = () => {
    setSelectedInvoices([])
    unCheckAllCheckBoxes()
  }

  const selectAll = () => {
    if (data?.invoices) {
      const allOrderIds = data.invoices.map((invoice) => invoice.orders_id)
      setSelectedInvoices(allOrderIds)
    }
    checkAllCheckBoxes()
  }

  const unCheckAllCheckBoxes = () => {
    const elems = document.getElementsByName(
      'row-check',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.checked = false
      })
    }
  }

  const checkAllCheckBoxes = () => {
    const elems = document.getElementsByName(
      'row-check',
    ) as NodeListOf<HTMLInputElement>
    if (elems) {
      elems.forEach((elem: HTMLInputElement) => {
        elem.checked = true
      })
    }
  }

  const checkCheckBoxes = (idsToSelect: string[]) => {
    unCheckAllCheckBoxes()
    idsToSelect.forEach((id) => {
      const checkboxId = `row-check-${id}`
      const checkboxElement = document.getElementById(
        checkboxId,
      ) as HTMLInputElement
      if (checkboxElement) {
        checkboxElement.checked = true
      }
    })
  }
  //end checkbox functions

  //row highlight functions
  const [highlightedInvoices, setHighlightedInvoices] = useState<Array<string>>(
    [],
  )

  const selectEditedAfterShip = () => {
    if (data?.invoices) {
      setSelectedInvoices([])
      const idsToSelect = data.invoices
        .filter((invoice) => invoice.changed_after_ship)
        .map((invoice) => invoice.orders_id)
      setSelectedInvoices(idsToSelect)
      checkCheckBoxes(idsToSelect)
    }
  }

  const selectTxTax = () => {
    if (data?.invoices) {
      setSelectedInvoices([])
      const idsToSelect = data.invoices
        .filter((invoice) => invoice.is_tx_tax_order)
        .map((invoice) => invoice.orders_id)
      setSelectedInvoices(idsToSelect)
      checkCheckBoxes(idsToSelect)
    }
  }

  const selectTxTaxDiscount = () => {
    if (data?.invoices) {
      setSelectedInvoices([])
      const idsToSelect = data.invoices
        .filter((invoice) => invoice.is_tx_tax_discount_order)
        .map((invoice) => invoice.orders_id)
      setSelectedInvoices(idsToSelect)
      checkCheckBoxes(idsToSelect)
    }
  }
  const selectNonTxTaxDiscount = () => {
    if (data?.invoices) {
      setSelectedInvoices([])
      const idsToSelect = data.invoices
        .filter((invoice) => invoice.is_non_tx_tax_discount_order)
        .map((invoice) => invoice.orders_id)
      setSelectedInvoices(idsToSelect)
      checkCheckBoxes(idsToSelect)
    }
  }

  const selectNonTxTax = () => {
    if (data?.invoices) {
      setSelectedInvoices([])
      const idsToSelect = data.invoices
        .filter((invoice) => invoice.is_non_tx_tax_order)
        .map((invoice) => invoice.orders_id)
      setSelectedInvoices(idsToSelect)
      checkCheckBoxes(idsToSelect)
    }
  }

  const selectExportedUnverified = () => {
    if (data?.invoices) {
      setSelectedInvoices([])
      const idsToSelect = data.invoices
        .filter(
          (invoice) =>
            invoice.exported_to_quickbooks_unverified_type ===
            'Exported Un-verified',
        )
        .map((invoice) => invoice.orders_id)
      setSelectedInvoices(idsToSelect)
      checkCheckBoxes(idsToSelect)
    }
  }

  const handleRowClick = (row: IInvoiceExportInvoice) => {
    tableData.forEach((item: any) => {
      if (item.is_highlighted && item.orders_id !== row.orders_id) {
        item.is_highlighted = false
      }
    })
    row.is_highlighted = !row.is_highlighted
    setHighlightedInvoices((highlightedInvoices) => [])
    if (row.is_highlighted) {
      setHighlightedInvoices((highlightedInvoices) => [row.orders_id])
    }
  }

  const exportCheckedToQuickbooks = async () => {
    await exportSelectedInvoiceExportInvoices(selectedInvoices)
    refetch()
  }

  const markCheckedExportedVerified = async () => {
    await markSelectedExportedVerified(selectedInvoices)
    refetch()
    selectNone()
  }

  const markCheckedExportedUnVerified = async () => {
    await markSelectedExportedUnverified(selectedInvoices)
    refetch()
  }

  const unmarkCheckedExportedUnVerified = async () => {
    await unmarkSelectedExportedUnverified(selectedInvoices)
    refetch()
  }

  const markCheckedAsNotProcessing = async () => {
    await markSelectedNotProcessing(selectedInvoices)
    refetch()
  }

  const exportCheckedToPdf = async () => {
    if (selectedInvoices.length === 0) {
      return
    }

    const orders_ids = selectedInvoices.join(',')
    await exportCheckedInvoicesPdf(orders_ids)
    setShowModal(true)
  }

  const exportSelectedToPdf = async () => {
    if (highlightedInvoices.length === 0) {
      return
    }

    const orders_ids = highlightedInvoices[0]
    await exportCheckedInvoicesPdfSingle(orders_ids)
  }

  const handleExportReconcileReport = async (month: string, year: string) => {
    // await setIsExporting(true)
    await setShowReconcileModal(false)
    await downloadReconcileReport(month, year)
    // await setIsExporting(false)
  }

  const handleReconcileModalClose = () => {
    setShowReconcileModal(false)
  }

  const handleClose = () => setShowModal(false)

  return (
    <div>
      <RwNavHeader
        pinKey={PinKey.ACCOUNTING_EXPORT_INVOICES}
        title="Export Invoices"
      >
        <RwNavBar>
          <RwIconButton
            handleClick={selectAll}
            button_text="Check All"
            icon_name="BiCheckboxChecked"
          ></RwIconButton>
          &nbsp;
          <RwIconButton
            handleClick={selectNone}
            button_text="Uncheck All"
            icon_name="BiCheckbox"
          ></RwIconButton>
          &nbsp;
          <CustomSelect
            title="Check"
            width="200px"
            handleSelect={handleCheckInvoiceSelect}
            options={actionCheckInvoices}
          />
          &nbsp;
          <div className={isDarkMode ? 'export-note-dm' : 'export-note'}>
            NOTE:{' '}
            <span className={isDarkMode ? 'pink-dm' : 'pink'}>
              Red - MO taxed with discount
            </span>
            ,{' '}
            <span className={isDarkMode ? 'light-green-dm' : 'light-green'}>
              Green - TX or LA taxed with or without discount
            </span>
          </div>
        </RwNavBar>
        <RwNavBar title={``}>
          <RwIconButton
            handleClick={exportCheckedToQuickbooks}
            button_text="Export to Quickbooks Online"
            icon_name="SiQuickbooks"
          ></RwIconButton>
          &nbsp;
          <CustomSelect
            title="Mark Checked Invoices"
            width="200px"
            handleSelect={handleMarkInvoiceSelect}
            options={actionMarkInvoices}
          />
          &nbsp;
          <RwIconButton
            handleClick={exportCheckedToPdf}
            button_text="Export checked to PDF"
            icon_name="AiOutlineFilePdf"
          ></RwIconButton>
          &nbsp;
          <RwIconButton
            handleClick={exportSelectedToPdf}
            button_text="Export selected to PDF"
            icon_name="AiOutlineFilePdf"
          ></RwIconButton>
          &nbsp;
          <RwIconButton
            handleClick={() => {
              setShowReconcileModal(true)
            }}
            button_text="Reconcile Report"
            icon_name="Excel"
          ></RwIconButton>
        </RwNavBar>
      </RwNavHeader>
      <div className="p-4">
        <RwTable hover>
          <RwTableHead>
            <RwTableRow>
              <RwTableCell as="th" style={{ width: '1%' }}></RwTableCell>
              <RwTableCell as="th">Order #</RwTableCell>
              <RwTableCell as="th">Shipped</RwTableCell>
              <RwTableCell as="th">Customer</RwTableCell>
              <RwTableCell as="th">QB Name</RwTableCell>
              <RwTableCell as="th">QB id</RwTableCell>
              <RwTableCell as="th">Sales</RwTableCell>
              <RwTableCell as="th">Type</RwTableCell>
              <RwTableCell as="th">Exported/Unverified</RwTableCell>
              <RwTableCell as="th">Date Exported/Unverified</RwTableCell>
              <RwTableCellEnd as="th">Subtotal</RwTableCellEnd>
              <RwTableCellEnd as="th">Charges</RwTableCellEnd>
              <RwTableCellEnd as="th">Special Charges</RwTableCellEnd>
              <RwTableCellEnd as="th">Discounts</RwTableCellEnd>
              <RwTableCellEnd as="th">Shipping Cost</RwTableCellEnd>
              <RwTableCellEnd as="th">Ship Discount</RwTableCellEnd>
              <RwTableCellEnd as="th">Tax</RwTableCellEnd>
              <RwTableCellEnd as="th">Order Total</RwTableCellEnd>
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            <>
              {tableData?.map((item: IInvoiceExportInvoice, index: number) => {
                return (
                  <RwTableRow
                    isHighlighted={item.is_highlighted}
                    className={`${item.row_color}${isDarkMode ? '-dm' : ''}`}
                    highlightedClassName={isDarkMode ? 'grey-dm' : 'grey'}
                    onDoubleClick={() => {
                      //handleDoubleClick(item.orders_id)
                    }}
                    onClick={() => {
                      handleRowClick(item)
                    }}
                    key={item.orders_id}
                    id={item.orders_id}
                  >
                    <RwTableCell>
                      <Form.Check
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            addToSelected(item.orders_id)
                          } else {
                            removeFromSelected(item.orders_id)
                          }
                        }}
                        name={'row-check'}
                        className="fs-5"
                        id={'row-check-' + item.orders_id}
                        defaultChecked={true}
                      />
                    </RwTableCell>
                    <RwTableCell>{item.orders_id}</RwTableCell>
                    <RwTableCell>{item.orders_date_finished}</RwTableCell>
                    <RwTableCell>{item.customers_name}</RwTableCell>
                    <RwTableCell>{item.qb_name}</RwTableCell>
                    <RwTableCell>{item.qb_id}</RwTableCell>
                    <RwTableCell>{item.sales_rep_initials}</RwTableCell>
                    <RwTableCell>{item.export_type}</RwTableCell>
                    <RwTableCell>
                      {item.exported_to_quickbooks_unverified_type}
                    </RwTableCell>
                    <RwTableCell>
                      {item.date_exported_to_quickbooks_unverified}
                    </RwTableCell>
                    <RwTableCellEnd>{item.subtotal}</RwTableCellEnd>
                    <RwTableCellEnd>{item.charges}</RwTableCellEnd>
                    <RwTableCellEnd>{item.special_charges}</RwTableCellEnd>
                    <RwTableCellEnd>{item.discounts}</RwTableCellEnd>
                    <RwTableCellEnd>{item.shipping_cost}</RwTableCellEnd>
                    <RwTableCellEnd>{item.shipping_discount}</RwTableCellEnd>
                    <RwTableCellEnd>{item.tax}</RwTableCellEnd>
                    <RwTableCellEnd>{item.order_total}</RwTableCellEnd>
                  </RwTableRow>
                )
              })}
              <RwTableRow className={isDarkMode ? 'total-row-dm' : 'total-row'}>
                <RwTableCell colSpan="10"> </RwTableCell>
                <RwTableCellEnd>{data?.column_totals.subtotal}</RwTableCellEnd>
                <RwTableCellEnd>{data?.column_totals.charges}</RwTableCellEnd>
                <RwTableCellEnd>
                  {data?.column_totals.special_charges}
                </RwTableCellEnd>
                <RwTableCellEnd>{data?.column_totals.discounts}</RwTableCellEnd>
                <RwTableCellEnd>{data?.column_totals.shipping}</RwTableCellEnd>
                <RwTableCellEnd>
                  {data?.column_totals.shipping_discount}
                </RwTableCellEnd>
                <RwTableCellEnd>{data?.column_totals.tax}</RwTableCellEnd>
                <RwTableCellEnd>{data?.column_totals.total}</RwTableCellEnd>
              </RwTableRow>
            </>
          </RwTableBody>
        </RwTable>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Export Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will receive an email with the exported PDFs shortly
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <ReconcileModal
        show={showReconcileModal}
        handleClose={handleReconcileModalClose}
        handleDownload={handleExportReconcileReport}
      />
    </div>
  )
}

export default InvoiceExport
