import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { monthOptions } from '../../../../constants/constants'
import RwCloseButton from '../../../molecules/RwButton/RwCloseButton'
import RwButton from '../../../molecules/RwButton/RwButton'
import RwOptionSelect from '../../../molecules/RwOptionSelect'
import { ISelectOption } from '../../../../types/interfaces'

interface ReconcileModalProps {
  show: boolean
  handleClose: () => void
  handleDownload: (month: string, year: string) => void
}

const ReconcileModal: React.FC<ReconcileModalProps> = ({
  show,
  handleClose,
  handleDownload,
}) => {
  const today = new Date()
  today.setMonth(today.getMonth() - 1)
  const defaultYear = today.getFullYear()
  const defaultMonth = today.getMonth() + 1

  const [month, setMonth] = useState(defaultMonth.toString())
  const [year, setYear] = useState(defaultYear.toString())

  const currentYear = new Date().getFullYear()
  const yearOptions: Array<ISelectOption> = [
    { value: '', label: '<Select Year>', id: '' },
    {
      value: currentYear.toString(),
      label: currentYear.toString(),
      id: currentYear.toString(),
    },
    {
      value: (currentYear - 1).toString(),
      label: (currentYear - 1).toString(),
      id: (currentYear - 1).toString(),
    },
    {
      value: (currentYear - 2).toString(),
      label: (currentYear - 2).toString(),
      id: (currentYear - 2).toString(),
    },
    {
      value: (currentYear - 3).toString(),
      label: (currentYear - 3).toString(),
      id: (currentYear - 3).toString(),
    },
    {
      value: (currentYear - 4).toString(),
      label: (currentYear - 4).toString(),
      id: (currentYear - 4).toString(),
    },
    {
      value: (currentYear - 5).toString(),
      label: (currentYear - 5).toString(),
      id: (currentYear - 5).toString(),
    },
  ]

  return (
    <Modal show={show} animation={false}>
      <Modal.Header>
        <Modal.Title>Select Month and Year</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RwOptionSelect
          onChange={(e: any) => {
            setMonth(e.target.value)
          }}
          label="Month"
          options={monthOptions}
          value={month}
        ></RwOptionSelect>
        <RwOptionSelect
          label="Year"
          options={yearOptions}
          onChange={(e: any) => {
            setYear(e.target.value)
          }}
          value={year}
        ></RwOptionSelect>
      </Modal.Body>
      <Modal.Footer>
        <RwButton
          onClick={() => {
            handleDownload(month, year)
            setYear(defaultYear.toString())
            setMonth(defaultMonth.toString())
          }}
        >
          Download
        </RwButton>
        <RwCloseButton onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  )
}

export default ReconcileModal
